<template>
  <b-modal
    :id="`modal-detail-promotion-train-${itineraryIndex}-${trainId}`"
    title="Danh sách khuyến mãi"
    title-class="font-medium-4 fw-600 text-primary"
    header-bg-variant="light-primary"
    centered
    size="lg"
    body-class="px-25 px-lg-1"
    @hide="hideModal"
    @show="showModal"
  >
    <IAmOverlay
      :loading="loadingGetPromotion"
      :spinner-variant="'info'"
    >
      <b-card body-class="py-1">
        <div class="fw-800 mb-1">
          {{ $t('train.search.result.trainPromotions') }} {{ trainCode }}
        </div>

        <template v-if="!isEmpty(trainPromotion) && !isEmpty(trainPromotion.listPromotionDisplay)">
          <b-row
            v-for="(promo, indexPromo) of trainPromotion.listPromotionDisplay"
            :key="indexPromo"
            class="border-top pt-25 mt-25"
          >
            <!-- ${isMobileView ? 'font-small-4' : 'font-medium-2'} -->
            <b-col
              cols="12"
              md="6"
              :class="`fw-700 px-0 px-lg-1`"
            >
              {{ promo.promotionContent }}
            </b-col>
            <!-- ${isMobileView ? 'font-small-3' : 'font-medium-2'} -->
            <b-col
              cols="12"
              md="6"
              :class="`fw-700 text-success px-0 px-lg-1
                `"
            >
              {{ promo.promotionContentDetail }}
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <div class="text-warning font-italic fw-600">
            {{ $t('train.search.result.noPromotions') }}
          </div>
        </template>
      </b-card>
    </IAmOverlay>

    <template #modal-footer="{ cancel }">
      <div class="d-flex-center w-100">
        <b-button
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          @click="handleSubmit"
        >
          <div class="d-flex-center px-25">
            Xác nhận
          </div>
        </b-button> -->
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BModal, BCard, BRow, BCol,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'

import useTrainHandle from '@train/useTrainHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,

    IAmOverlay,
  },
  props: {
    trainId: {
      type: Number,
      required: true,
    },
    trainCode: {
      type: String,
      required: true,
    },
    itineraryIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      getBookingCode,
      getResultTrainArray,
    } = useTrainHandle()
    const { toastError } = useToast()

    const loadingGetPromotion = ref(false)
    const trainPromotion = ref()

    async function handleGetPromotion() {
      loadingGetPromotion.value = true

      try {
        const train = getResultTrainArray.value[props.itineraryIndex].find(train => train.id === props.trainId)
        if (!train) {
          toastError({ title: 'train.search.result.searchError' })
          throw Error('Cannot found train data')
        }
        if (train?.promotionByTrain) {
          trainPromotion.value = train.promotionByTrain
        } else {
          const payload = {
            bookingCode: getBookingCode.value,
            trainId: train.id,
            getPromotionDetail: {
              trainCode: train.trainCode,
              dayOfStart: train.dayOfStart,
              departureTime: train.departureTime,
              departure: train.departure,
              arrival: train.arrival,
              departureKM: train.departureKM,
              arrivalKM: train.arrivalKM,
              departureHour: train.departureHour,
              arrivalHour: train.arrivalHour,
            },
          }
          const res = await store.dispatch('app-train/searchPromotions', payload)
          trainPromotion.value = res
        }
      } catch (error) {
        console.error({ error })
      } finally {
        loadingGetPromotion.value = false
      }
    }

    async function showModal() {
      handleGetPromotion()
    }

    function hideModal() {
      trainPromotion.value = undefined
    }

    return {
      isEmpty,
      showModal,
      hideModal,
      trainPromotion,
      formatCurrency,
      convertISODateTime,
      loadingGetPromotion,
    }
  },
}
</script>
