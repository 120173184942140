var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-detail-promotion-train-".concat(_vm.itineraryIndex, "-").concat(_vm.trainId),
      "title": "Danh sách khuyến mãi",
      "title-class": "font-medium-4 fw-600 text-primary",
      "header-bg-variant": "light-primary",
      "centered": "",
      "size": "lg",
      "body-class": "px-25 px-lg-1"
    },
    on: {
      "hide": _vm.hideModal,
      "show": _vm.showModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex-center w-100"
        }, [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])], 1)];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingGetPromotion,
      "spinner-variant": 'info'
    }
  }, [_c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "fw-800 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.trainPromotions')) + " " + _vm._s(_vm.trainCode) + " ")]), !_vm.isEmpty(_vm.trainPromotion) && !_vm.isEmpty(_vm.trainPromotion.listPromotionDisplay) ? _vm._l(_vm.trainPromotion.listPromotionDisplay, function (promo, indexPromo) {
    return _c('b-row', {
      key: indexPromo,
      staticClass: "border-top pt-25 mt-25"
    }, [_c('b-col', {
      class: "fw-700 px-0 px-lg-1",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_vm._v(" " + _vm._s(promo.promotionContent) + " ")]), _c('b-col', {
      class: "fw-700 text-success px-0 px-lg-1\n              ",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_vm._v(" " + _vm._s(promo.promotionContentDetail) + " ")])], 1);
  }) : [_c('div', {
    staticClass: "text-warning font-italic fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.noPromotions')) + " ")])]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }